import { gql } from '@apollo/client';
export const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput) {
    updateUser(input: $input) {
      id
      email
      fullName
    }
  }
`;
